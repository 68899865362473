import "./ThreeScene.css";

// grid react bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ThreeCanvas from "../components/ThreeCanvas";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';



// react three fiber
// https://github.com/pmndrs/react-three-fiber
// drei samples https://github.com/pmndrs/drei/tree/master >>>>>>>>>>>>> gold

// fun examples:
// grass: https://codesandbox.io/s/r3f-fairly-realistic-grass-forked-2r2t97?file=/src/Grass.jsx
// gallery: https://codesandbox.io/s/lx2h8
// Train moving... intersting.. mix this with animation:
// https://codesandbox.io/s/l900i    
// GLTF Animation: https://codesandbox.io/s/pecl6
// Limit rotation: https://codesandbox.io/s/kheke
// responsive 3d location: https://codesandbox.io/s/x6obrb
// 3d words, mix with chatgpt: https://codesandbox.io/s/yup2o
// fun scroller for images: https://codesandbox.io/s/l4klb
// scroll animate: https://codesandbox.io/s/gsm1y  ,  https://codesandbox.io/s/4m0d0  ,  https://codesandbox.io/s/4jr4p
// line render and pivot controls: https://codesandbox.io/s/2ij9u?file=/src/App.js:2909-2922
// editor grid expand with pivot controls: https://codesandbox.io/s/19uq2u <this one>--------------------- 
// pivot controls: and xyz in bottom right: https://codesandbox.io/s/om2ff8
// click for controls: https://codesandbox.io/s/btsbj
// first person controls: https://codesandbox.io/s/vkgi6
// camera controls, set camera target, animate to camera location, lerp look at: https://codesandbox.io/s/sew669
// wire frame mode: https://codesandbox.io/s/2572o5
// reflections: https://codesandbox.io/s/s006f
// video texture: https://codesandbox.io/s/2cemck
// instanced objects: https://codesandbox.io/s/h8o2d
// zoom to select: https://codesandbox.io/s/rz2g0
// color configurator: https://codesandbox.io/s/qxjoj
// water shader: https://codesandbox.io/s/1b40u?file=/src/App.js


export default function ThreeScene() {

  return (

    <div className="LeftNavColumnContainer">
      <div className="ThreeSceneRow">
        {/* left nav bar */}
        <Col className="LeftBarButtonGroupCol" sm={3} md={2} lg={2}>
          <div >

          {/* Button group top left */}
          <ButtonGroup vertical className="LeftBarButtonGroup">

            {/* Get the button click on this and then pass the props into the ThreeCanvas component to pull the correct 3d model */}
            {/* add 3D model list here */}
            <Button variant="outline-secondary">Project One</Button>
            <Button variant="outline-secondary">Project Two</Button>
            <Button variant="outline-secondary">Project Three</Button>
            

          </ButtonGroup>

          </div>
        </Col>

        <Col className="threeScene" sm={9} md={10} lg={10}>

          <ThreeCanvas/>

        </Col>
      </div>
    </div>



  );
}