import * as React from 'react';
import "./GPT.css";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import {SynthesizeSpeechCommand, PollyClient} from "@aws-sdk/client-polly";
import config from "../config";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CenteredModal from "../components/CenteredModal";
import { Configuration, OpenAIApi } from "openai";

let QuestionAndAnswerString = '';
let responseString = '';

export default function GPT() {

  let [showLoadingIcon, setShowLoadingIcon] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  let [image_url, setImgSrc] = React.useState('https://www.kurin.com/wp-content/uploads/placeholder-square.jpg');

  // Notes for call with Levin
  // Always an adventure story
  // Choose type of advneture
  // Space Odessey, Desert Explorer, Mystery Island, Others
  // 



  // initial prompt
  let [promptText, setPromptText] = React.useState('Tell me a two sentence story.');

  // Amazon Polly
  // creds to use Amazon Polly Creds. Put this into .env
  const creds = {
    accessKeyId: 'AKIA5RWJONZBNTHCMCFX',
    secretAccessKey: '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',
    // sessionToken: SessionToken
  };

  // a client can be shared by different commands.
  const pollyClient = new PollyClient({ 
    credentials: creds,
    region: config.cognito.REGION 
  });

  async function SendRobotThings(event) {

    event.preventDefault();
    setShowLoadingIcon(true);

    console.log("Send robot something.");
    document.getElementById('responseField').value = '';

    // get user input
    let userInput = document.getElementById('userInput').value;
    console.log("User Input: " + userInput);

    // build historic question and answer conversation string
    // QuestionAndAnswerString = QuestionAndAnswerString + '\n Q: ' + "Continue the story." + userInput;
    QuestionAndAnswerString = QuestionAndAnswerString + '\n Q: ' + userInput;

    const url = 'https://api.openai.com/v1/chat/completions';

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-Amz-Date': new Date().toISOString(),
      'Authorization': 'Bearer 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',
    });

    const data = {
      "model": "gpt-3.5-turbo",
      "messages": [{"role": "assistant", "content": QuestionAndAnswerString}],
      "max_tokens": 700
    };

    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    };

    fetch(url, options)
      .then(response => response.json())
      .then(data => 
        afterResponseReturn(data)
      )

      .catch(error => console.error(error)); 

  };

  async function afterResponseReturn(responseData){
    console.log(responseData)
    // setShowLoadingIcon(false);
    responseString = responseData.choices[0].message.content




    // cut out the "A:" for ChatGPTs response
    // console.log(responseString.includes('A:'));
    console.log(responseString);
    if (responseString.includes('A:')){
      let NewResponseString = responseString.replace('A:', '');
      responseString = NewResponseString;
      console.log(NewResponseString);
      document.getElementById('responseField').value = NewResponseString;
      QuestionAndAnswerString = QuestionAndAnswerString + '\n A: ' + NewResponseString.trim() + '\n';
    }else{
      document.getElementById('responseField').value = responseString;
      QuestionAndAnswerString = QuestionAndAnswerString + '\n A: ' + responseString.trim() + '\n';
    }

    console.log(QuestionAndAnswerString);

    // console.log("should attempt Amazon Polly")
    SendDalleThings();

    // Amazon Polly
    // voice list: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html
    // Create the Amazon Polly parameters
    var params = {
      Engine: "neural",
      OutputFormat: "mp3",
      OutputS3BucketName: "amazon-polly-bucket",
      Text: responseString,
      TextType: "text",
      VoiceId: "Salli",
      SampleRate: "8000",
    };

    // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-polly/classes/synthesizespeechcommand.html
    const command = new SynthesizeSpeechCommand(params);
    const responseAudioStream = await pollyClient.send(command);
    // console.log(responseAudioStream);
    let responseAudioStreamByteArray = responseAudioStream.AudioStream.transformToByteArray();
    // console.log(responseAudioStreamByteArray);

    const aContext = new AudioContext();
    const source = aContext.createBufferSource();
    source.buffer = await aContext.decodeAudioData((await responseAudioStreamByteArray).buffer);
    source.connect(aContext.destination);
    source.start();


    // send mp3 audio files to S3 bucket - not used
    // const run = async () => {
    //   try {
    //     await pollyClient.send(new StartSpeechSynthesisTaskCommand(params));
    //     console.log("Success, audio file added to " + params.OutputS3BucketName);

    //     // need to get ARN 
    //     // now to play it, download the file from s3 and play, or catch it before its sent off and play it.
    //     // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-polly/modules.html#:~:text=PollyClient-,SDK%20%2D%20Commands,-DeleteLexiconCommand
    //     // figure out in docs how to not just send off this file, but how to play the voice instantly.
    //     // dont send it off, just play it.


    //   } catch (err) {
    //     console.log("Error putting object", err);
    //   }
    // };
    // run();

  }

  async function SendDalleThings() {

    // event.preventDefault();
    // setShowLoadingIcon(true);

    console.log("Send dalle something.");

    // get user input
    // let userInput = document.getElementById('userInput').value;
    // console.log("User Input: " + userInput);

    const configuration = new Configuration({
      apiKey: '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',
    });
    const openai = new OpenAIApi(configuration);
    const response = await openai.createImage({
      // prompt: userInput,
      prompt: "monet painting style, " + responseString,
      n: 1,
      size: "1024x1024",
    });

    image_url = response.data.data[0].url;
    console.log('image: ' + image_url);
    setImgSrc(image_url);
    setShowLoadingIcon(false);

  };

  function clearChatHistory(){
    QuestionAndAnswerString = '';
    alert('cleared chat history');
  }

  // pull out the first x number of sentences from the text
  // function GetFirstSentences(responseString, numberOfSentences){

  //   let ResponseSentences = responseString.split('.');
  //   console.log('ResponseSentences: ');
  //   console.log(ResponseSentences);

  // }

  return (
    <div className="GPT container">

      <Container>
        <Row>
          <Col>
            <Form className='gptForm' onSubmit={SendRobotThings}>
              <Form.Group className="mb-3" controlId="userInput">
                <Form.Label>GPT Prompt</Form.Label>
                <Form.Control type="text" value={promptText} onChange={(e) => setPromptText(e.target.value)}/> 
              </Form.Group>

              <Button className='gptButton' variant="primary" onClick={(event) => SendRobotThings(event)}>
                Ask AI
              </Button>

              {/* conditionally show of hide the loading icon */}
              { showLoadingIcon ? 
              
              <Button variant="primary" className="loadingIcon2" disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
              </Button>
              
              : null }

              <Button className='clearChatHistory' variant="secondary" onClick={() => clearChatHistory()}>
                Clear Chat History
              </Button>

              <Form.Group className="mb-3" controlId="responseField">
                <Form.Label>AI Resonse</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
            </Form>
          </Col>
          <Col>
          
          <div className="GPT container">

            <Form className='gptForm' onSubmit={SendDalleThings}>
            </Form>

              {/* put image here */}
              <img src={image_url} alt="dalle" className="dalleImage"></img>

            </div>

          </Col>
        </Row>
      </Container>


      <CenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />            
  
    </div>
  );
}
