import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import ErrorBoundary from "./components/ErrorBoundary";
import { AppContext } from "./lib/contextLib";
import { onError } from "./lib/errorLib";
// import Footer from "./components/Footer";
import Routes from "./Routes";
import "./App.css";

function App() {
  const nav = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    nav("/login");
  }

  const [ marginLeft ] = useState('20px');
  const [ marginRight ] = useState('20px');

  // look to make this responsive here:
  // https://react-bootstrap.github.io/components/navbar/#:~:text=Copy-,Responsive,-behaviors

  return (
    !isAuthenticating && (
      <div className="App">
        <Navbar collapseOnSelect bg="white" expand="md" className="mb-2 navBar"> 
          <LinkContainer to="/" style={{ 'marginLeft': marginLeft}}>
            <Navbar.Brand className="fw-bold text-muted">
              Adam Website
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav activeKey={window.location.pathname} style={{ 'marginRight': marginRight}}>
              {isAuthenticated ? (
                <>
                  <LinkContainer to="/threeScene">
                    <Nav.Link>3D</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/scrollAnim">
                    <Nav.Link>ScrollAnim</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/GPT">
                    <Nav.Link>GPT</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/notes">
                    <Nav.Link>Notes</Nav.Link>
                  </LinkContainer>
                  {/* <LinkContainer to="/calendar">
                    <Nav.Link>Calendar</Nav.Link>
                  </LinkContainer> */}
                  <LinkContainer to="/settings">
                    <Nav.Link>Settings</Nav.Link>
                  </LinkContainer>
                  <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </>
              ) : (
                <>
                  <LinkContainer to="/threeScene">
                    <Nav.Link>3D</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/signup">
                    <Nav.Link>Signup</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <ErrorBoundary>
          <AppContext.Provider
            value={{ isAuthenticated, userHasAuthenticated }}
          >
            <Routes />
          </AppContext.Provider>
        </ErrorBoundary>
        {/* <Footer/> */}
      </div>
    )
  );
}

export default App;
