import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import React, { useRef, useState, useEffect } from "react";


export default function CenteredModal({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          {/* try to figure out how you would pass the useState content of the current note, */}
          {/* into the below text of the modal. Trying to understand how to move that data around. */}
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}