import "./ScrollCanvas.css";

import * as THREE from 'three'
import { Suspense, useEffect, useLayoutEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { ScrollControls, Center, ContactShadows, SoftShadows, AccumulativeShadows, RandomizedLight, Stage, OrbitControls, Plane, Sky, useScroll, useGLTF, Environment, useAnimations } from '@react-three/drei'
import  { useState, useRef } from 'react'
import { suspend } from 'suspend-react'
import { Leva, useControls } from "leva"

import Overlay from "./Overlay"


export default function ScrollCanvas({ ...props }) {

  const { enabled, ...config } = useControls({
    enabled: true,
    size: { value: 100, min: 0, max: 200 },
    focus: { value: 2, min: 0, max: 4 },
    samples: { value: 20, min: 1, max: 40, step: 1 }
  })

  function Env() {
    return <Environment files={suspend(city).default} background blur={2} />
  }

  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)

  // css
  const [ block ] = useState('block');
  const [ width ] = useState('100%');
  const [ height ] = useState('93vh');

  return (
    <>
    <Canvas shadows {...props}  camera={{ position: [5, 10, 10] }} style={{ 'display': block, 'width': width, 'height': height}}>
      {enabled && <SoftShadows {...config} />}
      <fog attach="fog" args={["white", 0, 40]} />
      {/* <ambientLight intensity={0.5} /> */}

      {/* hide ui panel */}
      <Leva  hidden/>

      <spotLight angle={0.20}  penumbra={1} position={[25, 50, -20]} shadow-mapSize={[2048, 2048]} shadow-bias={-0.0001} castShadow />
      {/* <Sky scale={10000} sunPosition={[2, 0.4, 10]} /> */}

      <Env />
      {/* <OrbitControls makeDefault /> */}

      <mesh rotation={[-Math.PI / 2, 0, 0]}  position={[0, 1, 0]} receiveShadow castShadow>
          <planeGeometry args={[1000, 1000]} />
          <shadowMaterial transparent opacity={0.4} />
      </mesh>

      {/* scroll anim */}
      <Suspense fallback={null}>

        {/* Wrap contents you want to scroll into <ScrollControls> */}
        <ScrollControls pages={15}>
        <mesh receiveShadow castShadow>
          <LittlestTokyo scale={1.5} position={[0, 2.5, 0]} rotation={[0,0,0]} />
          <shadowMaterial transparent opacity={0.4} />
        </mesh>
        </ScrollControls>

      </Suspense>

    </Canvas>
    {/* <Overlay ref={overlay} caption={caption} scroll={scroll} /> */}
    </>
  )
}

const city = import('./city.hdr')

function LittlestTokyo({ ...props }) {
  // This hook gives you offets, ranges and other useful things
  const scroll = useScroll()
  // const scroll = useRef(0)
  // const { scene, nodes, animations } = useGLTF('/LittlestTokyo-transformed.glb') // model must be in public folder
  const { scene, nodes, animations } = useGLTF('/AnimTest.glb') // model must be in public folder

  const { actions } = useAnimations(animations, scene)
  useLayoutEffect(() => Object.values(nodes).forEach((node) => (node.receiveShadow = node.castShadow = true)))

  // animation name from blender
  useEffect(() => void (actions['Task'].play().paused = true), [actions])

  useFrame((state, delta) => {
      const action = actions['Task']
      // The offset is between 0 and 1, you can apply it to your models any way you like
      const offset = 1 - scroll.offset

      // duration = duration of the clip. can show only part of the animation if desired, or the whole thing
      action.time = THREE.MathUtils.damp(action.time, (action.getClip().duration) * offset, 100, delta)
      // state.camera.position.set(Math.sin(offset) * -10, Math.atan(offset * Math.PI * 2) * 5, Math.cos((offset * Math.PI) / 3) * -10)
      // state.camera.lookAt(0, 0, 0)
  })
  return <primitive object={scene} {...props} />
}

// useGLTF.preload('/LittlestTokyo-transformed.glb') // model must be in public folder
useGLTF.preload('/AnimTest.glb') // model must be in public folder




