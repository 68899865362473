import  { memo, useState } from 'react'
import { Grid, GizmoHelper, GizmoViewport, AccumulativeShadows, RandomizedLight, Environment, useGLTF } from '@react-three/drei'
import { Leva, useControls } from 'leva'
import "./ThreeCanvas.css";

import { Canvas } from '@react-three/fiber'
import { OrbitControls, ContactShadows, useCursor } from '@react-three/drei'
import { proxy, useSnapshot } from 'valtio'
import { PivotControls } from '@react-three/drei';
import { suspend } from 'suspend-react'

// get hdri images here: 
const city = import('./city.hdr')

export default function ThreeCanvas() {

  // Reactive state model, using Valtio ...
  const modes = ['translate', 'rotate', 'scale']
  const state = proxy({ current: null, mode: 0 })

  function Env() {
    return <Environment files={suspend(city).default} background blur={1} />
  }

  // Need better way to load 3D models
  // create three js model from nested geometry inside compressed.glb
  // to recreate this, open .glb structure in blender and recreate

  function Model({ name, ...props }) {
    // Ties this component to the state model
    const snap = useSnapshot(state)
    // Fetching the GLTF, nodes is a collection of all the meshes
    // It's cached/memoized, it only gets loaded and parsed once
    const { nodes } = useGLTF('/compressed.glb')
    // const { nodes } = useGLTF('https://market-assets.fra1.cdn.digitaloceanspaces.com/market-assets/models/suzanne-high-poly/model.gltf')
    // Feed hover state into useCursor, which sets document.body.style.cursor to pointer|auto
    const [hovered, setHovered] = useState(false)
    useCursor(hovered)

    // check in console if the meshes you are pulling in are good.
    // to make this work, in blender, you need to have an empty parent of all of the children meshes. see .glbs in public folder for example
    // also you need to make sure that the model names below in the return match the names of the children mdoels in the .glb
    // console.log(name);
    // console.log(nodes);
    // console.log(nodes[name])

    return (
      
      <PivotControls className={name} rotation={[0, -Math.PI / 2, 0]} anchor={[0, 0, 0]} 
      // this is where we "turn on" the transform controls, realy we ar scaling them from zero to 75
      scale={snap.current === name ? 75 : 0.0001} depthTest={false} fixed lineWidth={snap.current === name ? 2 : 0.0001}>
        <mesh
          castShadow
          receiveShadow

          // Click sets the mesh as the new target
          onClick={(e) => (e.stopPropagation() (state.current = name))}
          // If a click happened but this mesh wasn't hit we null out the target,
          // This works because missed pointers fire before the actual hits
          onPointerMissed={(e) => e.type === 'click' && (state.current = null)}
          // Right click cycles through the transform modes
          onContextMenu={(e) => snap.current === name && (e.stopPropagation(), (state.mode = (snap.mode + 1) % modes.length))}
          onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
          onPointerOut={(e) => setHovered(false)}
          name={name}
          geometry={nodes[name].geometry}
          material={nodes[name].material}
          material-color={snap.current === name ? '#ffc1bd' : 'white'}
          {...props}
          dispose={null}
        >
        </mesh>
    </PivotControls>


    )
  }


  const { gridSize, ...gridConfig } = useControls({
    gridSize: [10.5, 10.5],
    cellSize: { value: 0.6, min: 0, max: 10, step: 0.1 },
    cellThickness: { value: 1, min: 0, max: 5, step: 0.1 },
    cellColor: '#6f6f6f',
    sectionSize: { value: 3.3, min: 0, max: 10, step: 0.1 },
    sectionThickness: { value: 1.5, min: 0, max: 5, step: 0.1 },
    sectionColor: '#9d4b4b',
    fadeDistance: { value: 25, min: 0, max: 100, step: 1 },
    fadeStrength: { value: 1, min: 0, max: 1, step: 0.1 },
    followCamera: false,
    infiniteGrid: true
  })

  // css
  const [ block ] = useState('block');
  const [ width ] = useState('100%');
  const [ height ] = useState('93vh');
  const [ touchAction ] = useState('none');

  return (

    <Canvas shadows camera={{ position: [5, 6, 18], fov: 25 }} style={{ 'display': block, 'width': width, 'height': height, 'touchAction': touchAction }}>
      
      {/* Turn off the Leva grid editor panel */}
      <Leva  hidden/>

      {/* Pulling in 3D Model tests */}
      {/* because we dont know the names of the sub children models before hand, we need to use a map by index strategy */}
      {/* {nodes.map((name, index) => (
        <Model key={index} name={name} nodes={nodes} position={[0, 0, 0]} rotation={[0, 0, 0]} scale={[1,1,1]}/>
      ))} */}

      {/* {nodes.map(({ childModelName }) => (
        <Model name={childModelName} nodes={nodes} position={[0, 0, 0]} rotation={[0, 0, 0]} scale={[1,1,1]}/>
      ))} */}

      {/* Need to dynamically pass in model name below depending on objects in the scene */}

      {/* only displaying certain nested 3d models from the compressed gltf */}
      <Model name="Headphones" position={[-1.5, 1.3, 0]} rotation={[1, 0, -1]} scale={[0.1,0.1,0.1]}/>
      <Model name="Notebook" position={[3.5, 1, 0]} rotation={[2, 0, 1]} scale={[0.1,0.1,0.1]}/>
      <Model name="Rocket003" position={[-3, .8, 0]} rotation={[1, 1, 0]} scale={[0.1,0.1,0.1]}/>
      <Model name="VR_Headset" position={[1, 1, 0]} rotation={[1, 0, -1]} scale={[0.45,0.45,0.45]} />

      <Shadows />
      <ContactShadows rotation-x={Math.PI / 2} position={[0, -35, 0]} opacity={0.25} width={200} height={200} blur={1} far={50} />

      <Grid position={[0, -0.01, 0]} args={gridSize} {...gridConfig} />

      <OrbitControls makeDefault />
      <Env />

      <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
        <GizmoViewport axisColors={['#9d4b4b', '#2f7f4f', '#3b5b9d']} labelColor="white" />
      </GizmoHelper>

    </Canvas>
  )
}

const Shadows = memo(() => (
  <AccumulativeShadows temporal frames={100} color="#9d4b4b" colorBlend={0.5} alphaTest={0.9} scale={20}>
    <RandomizedLight amount={8} radius={4} position={[5, 5, -10]} />
  </AccumulativeShadows>
))


