import React, { useState } from "react";
import "./Dalle.css";
import "./GPT.css";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
const { Configuration, OpenAIApi } = require("openai");

export default function Dalle(props) {

  let [image_url, setImgSrc] = useState('https://www.kurin.com/wp-content/uploads/placeholder-square.jpg');
  
  // ToDo
  // Trigger function in Dalle child component from parent GPT component

  async function SendDalleThings(event) {

    event.preventDefault();
    setShowLoadingIcon(true);

    console.log("Send dalle something.");

    // get user input
    // let userInput = document.getElementById('userInput').value;
    // console.log("User Input: " + userInput);

    const configuration = new Configuration({
      apiKey: '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',
    });
    const openai = new OpenAIApi(configuration);
    const response = await openai.createImage({
      // prompt: userInput,
      prompt: "monet painting style, " + props.response,
      n: 1,
      size: "1024x1024",
    });

    image_url = response.data.data[0].url;
    console.log('image: ' + image_url);
    setImgSrc(image_url);
    setShowLoadingIcon(false);

  };

  let [showLoadingIcon, setShowLoadingIcon] = React.useState(false)


  return (
    <div className="GPT container">

      <Form className='gptForm' onSubmit={SendDalleThings}>
        {/* <Form.Group className="mb-3" controlId="userInput">
          <Form.Label>Dalle Prompt</Form.Label>
          <Form.Control type="text" placeholder="tell robot somthing"/>
        </Form.Group> */}

        <Button className='dalleButton' variant="primary" onClick={(event) => SendDalleThings(event)}>
          Create Image
        </Button>

        {/* conditionally show of hide the loading icon */}
        { showLoadingIcon ? 
        
        <Button variant="primary" className="loadingIcon" disabled>
          <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
        </Button>
        
        : null }


      </Form>

        {/* put image here */}
        <img src={image_url} alt="dalle" className="dalleImage"></img>
  
    </div>
  );
}
