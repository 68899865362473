import React, { useState, useEffect } from "react";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import "./Home.css";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import CenteredModal from "../components/CenteredModal";

// this is how you can craete a component from a block of JSX, then pull it into other functions
// to stay organized
// import DogCarousel from "../components/DogCarousel";
import GoogleMapOpto from "../components/GoogleMapOpto";

export default function Home() {
  const [notes, setNotes] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const notes = await loadNotes();
        setNotes(notes);
        // trying to see the structure here
        console.log("notes object: ");
        console.log(notes);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadNotes() {
    return API.get("notes", "/notes");
  }

  const [modalShow, setModalShow] = React.useState(false);

  function renderNotesList(notes) {
    return (
      <>
      <Container>
        <Row>
          <Col>
          <LinkContainer to="/notes/new">
            <ListGroup.Item action className="py-3 text-nowrap text-truncate">
              <BsPencilSquare size={15} />
              <span className="ms-2 fw-bold">New note</span>
            </ListGroup.Item>
          </LinkContainer>
          {notes.map(({ noteId, content, createdAt }) => (
            <LinkContainer key={noteId} to={`/notes/${noteId}`}>
              <ListGroup.Item action className="text-nowrap text-truncate">
                <span className="fw-bold">
                  {content.trim().split("\n")[0]}
                </span>
                <br />
                <span className="text-muted">
                  Created: {new Date(createdAt).toLocaleString()}
                </span>

                <Button onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  setModalShow(true);
                  }} 
                  size="sm" className="cuteButton">button</Button>
                
                <CenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />

              </ListGroup.Item>
            </LinkContainer>
          ))}
          </Col>
          <Col>
            {/* second column for use later */}

            {/* <DogCarousel></DogCarousel> */}
            <GoogleMapOpto></GoogleMapOpto>

          </Col>
        </Row>
      </Container>
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>PictureBook.AI</h1>
        <p className="text-muted">Go on an adventure.</p>
      </div>
    );
  }

  function renderNotes() {
    return (
      <div className="notes container">
        <h5 className="pb-3 mt-4 mb-3 border-bottom">Notes</h5>
        <ListGroup>{!isLoading && renderNotesList(notes)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderNotes() : renderLander()}
    </div>
  );
}
