import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';

export default function GoogleMapOpto() {

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: 40.720112,
    lng: -73.995541
  };

  const markerPosition = {
    lat: 40.720112,
    lng: -73.995541
  }
  
  const onLoad = marker => {
    console.log('marker: ', marker)
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC_qQAdfEgTqqs3CI5FhQdeNVgPYIYhTtI'
  })

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
      >
        <Marker
          onLoad={onLoad}
          position={markerPosition}
        />
        <></>
      </GoogleMap>
  ) : <></>

};
