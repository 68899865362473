import * as React from 'react';
import "./Calendar.css";
import Button from 'react-bootstrap/Button';
import { gapi } from "gapi-script";

export default function Calendar() {

    // Guide:
    // https://stateful.com/blog/google-calendar-react
    // Get Access Token:
    // https://developers.google.com/oauthplayground/

    // need to refresh this
    const accessToken = "ya29.a0Ael9sCN-1AGv98AI2HSBOv2JE6gDMufBCdIJnnELLWG0MTd3_erSOtLXQsuYsjkRC_JgTnTRcEhUw5Y3qsO3SEMoHj-HUj7_hEEqUGpT1ivkvf6yxGM029dD51yZJc6x1QgxNoPQKM35hAPvhnFr_5GJS5x-aCgYKARQSARASFQF4udJhbBGUQ6BEHDtBfmd5G8d8sA0163";


    const addEvent = () => {
      function initiate() {
        gapi.client
          .request({
            // calendar id: 237dd54827ac5e033ddfb8ebafa95027ceef08b3d5d166cf3654e793bb7300db@group.calendar.google.com
            path: `https://www.googleapis.com/calendar/v3/calendars/237dd54827ac5e033ddfb8ebafa95027ceef08b3d5d166cf3654e793bb7300db@group.calendar.google.com/events`,
            method: "POST",
            
            // how to create an event and push to google calendar
            body: {
              summary: "Booking",
              location: "36 Darm",
              start: {
                dateTime: "2023-04-11T09:00:00-07:00", 
                timeZone: "America/New_York",
              },
              end: {
                dateTime: "2023-04-11T17:00:00-07:00",
                timeZone: "America/New_York",
              },
              attendees: [""],
              reminders: {
                useDefault: false,
                overrides: [
                  { method: "email", minutes: 24 * 60 },
                  { method: "popup", minutes: 10 },
                ],
              },
            },

            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(
            (response) => {

              setTimeout(function(){
                window.location.reload();
              }, 1000);

              return [true, response];

            },
            function (err) {
              console.log(err);
              alert(err.body);
              return [false, err];
            }
          );
      }
      gapi.load("client", initiate);
    };
    

    const UpdateCalendar = (event) => {
        // do something
        console.log("update calendar.")

        addEvent();
    };

  return (
    <div className="Calendar container" >

        <iframe title="calendar" 
        src="https://calendar.google.com/calendar/embed?src=237dd54827ac5e033ddfb8ebafa95027ceef08b3d5d166cf3654e793bb7300db%40group.calendar.google.com&ctz=America%2FNew_York" 
        width="100%" height="600vh" ></iframe>

        <Button className="calendarButton" variant="primary" onClick={() => UpdateCalendar()}>
            Add Booking
        </Button>
   
    </div>
  );
}