import "./ScrollAnim.css";

// grid react bootstrap
// import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import ThreeCanvas from "../components/ThreeCanvas";
import ScrollCanvas from "../components/ScrollCanvas";

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

// import Overlay from "./Overlay"
// import  { useRef } from 'react'

// react three fiber
// https://github.com/pmndrs/react-three-fiber
// drei samples https://github.com/pmndrs/drei/tree/master 


// scroll animate: 
// https://codesandbox.io/s/4m0d0
// https://codesandbox.io/s/l900i   

// https://codesandbox.io/s/4jr4p

// https://codesandbox.io/s/tu24h?file=/src/Model.js


export default function ScrollAnim() {

  // const overlay = useRef()
  // const caption = useRef()
  // const scroll = useRef(0)

  return (

    <div className="LeftNavColumnContainer">
      <div className="ThreeSceneRow">
        {/* left nav bar */}
        <Col className="LeftBarButtonGroupCol" sm={3} md={2} lg={2}>
          <div >

          {/* Button group top left */}
          <ButtonGroup vertical className="LeftBarButtonGroup">

            {/* Get the button click on this and then pass the props into the ThreeCanvas component to pull the correct 3d model */}
            {/* add 3D model list here */}
            <Button variant="outline-secondary">Project One</Button>
            <Button variant="outline-secondary">Project Two</Button>
            <Button variant="outline-secondary">Project Three</Button>
            <Button variant="outline-secondary">Project Four</Button>

          </ButtonGroup>

          </div>
        </Col>

        {/* scroll text overlay */}


        <Col className="scrollScene" sm={9} md={10} lg={10}>

            {/* To Do */}
            {/* I have the crolling test working and the scrolling anim working */}
            {/* the problem is getting them to work together.. */}
            {/* the issue is in styles.css in the .scroll class. */}
            {/* it looks like it is taking the scroll input from the other input */}
            {/* They are not able to share..  */}
            {/* This example is doing both.. https://codesandbox.io/s/camera-scroll-forked-s8tv5t?file=/src/Model.js:332-337 */}
            {/* I tried passing through props and didn't work */}

            {/* COPY THIS EXAMPLE EXACTLY */}
            {/* https://codesandbox.io/s/camera-scroll-forked-s8tv5t?file=/src/Model.js */}

            <ScrollCanvas />
            {/* <Overlay ref={overlay} caption={caption} scroll={scroll} /> */}

            {/* <ThreeCanvas /> */}

        </Col>
      </div>
    </div>



  );
}